(function ($) {
  Drupal.behaviors.productGridCoresiteV1 = {
    templateContainer: $('.js-mpp-product-coresite'),
    initProductGridCoresiteV1: function (context) {
      var self = this;
      var $productContainter = self.templateContainer.find('.js-product-wrapper', context);
      var animate = false;

      // Open learn more popup
      $productContainter.find('.js-btn-learn-more').on('click', function () {
        var prodId = $(this).closest('.js-product-wrapper').attr('data-productid');
        var product;

        if (typeof prodId !== 'undefined') {
          if ($('.js-mpp-database-product-coresite').length) {
            product = $(page_data['catalog-mpp'].categories[0].products).filter(function () {
              return this.PRODUCT_ID === prodId;
            })[0];
          } else {
            product = $(page_data['product-input-type'].products).filter(function () {
              return this.PRODUCT_ID === prodId;
            })[0];
          }

          $('.js-product-learn-more-popup-right-name h2').html(product.PROD_RGN_NAME);
          $('.js-product-learn-more-popup-right-desc p').html(product.DESCRIPTION);
          $('.js-product-learn-more-wrapper').show();
          $('body').css({
            overflow: 'hidden',
            'margin-right': '25px'
          });

          // Load link for external shop now buttons if exist
          if (product.EFFICACY_MSG !== null) {
            $('.js-product-learn-more-popup-right-shop-button').parent().attr('href', product.EFFICACY_MSG);
            $('.js-product-learn-more-popup-right-shop-button').show();
          }

          if (product.shaded) {
            var skus = product.skus;
            var hexValue = $(this).closest('.js-product-wrapper').find('.js-slider-slide.active').attr('data-hex');
            var nameValue = $(this).closest('.js-product-wrapper').find('.js-slider-slide.active').attr('aria-label');
            var skuId = $(this).closest('.js-product-wrapper').find('.js-slider-slide.active').attr('data-sku-id');

            $('.js-product-shade-picker-dropdown-shade-hex-icon').css('background-color', hexValue);
            $('.js-product-shade-picker-dropdown-shade-name').html(nameValue);
            $('.js-product-learn-more-popup-left-img').attr('src', '/media/export/cms/products/600x750/cl_sku_' + skuId + '_600x750_0.png');
            $('.js-product-shade-picker-dropdown').show();

            $(skus).each(function (index, value) {
              $('.js-product-shade-picker-dropdown-options-list').append('<li role="option" class="product-shade-picker-dropdown-option js-product-shade-picker-dropdown-option" title="' + value.SHADENAME + '" data-sku-id="' + value.SKU_BASE_ID + '"><div class="product-shade-picker-dropdown-shade-hex"><div class="product-shade-picker-dropdown-shade-hex-icon" style="background-color:' + value.HEX_VALUE_STRING + '"></div></div>' + value.SHADENAME + '</li>');
            });

            // Open/Close shades dropdown
            $('.js-product-shade-picker-dropdown-wrapper').on('click', function () {
              if ($('.js-product-shade-picker-dropdown-options-list').is(':hidden')) {
                $('.js-product-shade-picker-dropdown-shade-arrow').addClass('open');
                $('.js-product-shade-picker-dropdown-options-list').show();
              } else {
                $('.js-product-shade-picker-dropdown-options-list').hide();
                $('.js-product-shade-picker-dropdown-shade-arrow').removeClass('open');
              }
            });

            // Select shade
            $('.js-product-shade-picker-dropdown-option').on('click', function () {
              var skuBaseId = parseInt($(this).attr('data-sku-id'), 10);

              $(skus).each(function (index, value) {
                if (skuBaseId === value.SKU_BASE_ID) {
                  $('.js-product-learn-more-popup-left-img').attr('src', '/media/export/cms/products/600x750/cl_sku_' + value.PRODUCT_CODE + '_600x750_0.png');
                  $('.js-product-shade-picker-dropdown-shade-name').html(value.SHADENAME);
                  $('.js-product-shade-picker-dropdown-shade-hex-icon').css('background-color', value.HEX_VALUE_STRING);
                  $('.js-product-shade-picker-dropdown-options-list').hide();
                  $('.js-product-shade-picker-dropdown-shade-arrow').removeClass('open');
                }
              });
            });
          } else {
            $('.js-product-learn-more-popup-left-img').attr('src', '/media/export/cms/products/600x750/cl_sku_' + product.FAMILY_CODE + '_600x750_0.png');
          }

          // Close popup
          $('.js-product-learn-more-popup-close-button').on('click', function () {
            $('.js-product-learn-more-wrapper').hide();
            $('.js-product-learn-more-popup-right-shop-button').hide();
            $('.js-product-shade-picker-dropdown').hide();
            $('.js-product-shade-picker-dropdown-options-list').hide();
            $('.js-product-shade-picker-dropdown-options-list').empty();
            $('.js-product-shade-picker-dropdown-shade-arrow').removeClass('open');
            $('.js-product-shade-picker-dropdown-wrapper').unbind('click');
            $('body').css({
              overflow: 'auto',
              'margin-right': '0'
            });
          });
        }
      });

      // Mouseover/mouseout on name of single product
      $('.js-product-info-wrapper-mouseover, .js-product-shade-slider-mouseover').mouseover(function () {
        $(this).parent().find('.js-product-name').hide();
        $(this).parent().find('.js-product-short-description, .js-product-learn-more-button, .js-product-shades-count').show();
      }).mouseout(function () {
        $(this).parent().find('.js-product-name').show();
        $(this).parent().find('.js-product-short-description, .js-product-learn-more-button, .js-product-shades-count').hide();
      });

      // Mouseover/mouseout on image of single product
      $('.js-product-image-wrapper-mouseover').mouseover(function () {
        $(this).parent().find('.js-product-name, .js-product-badge').hide();
        $(this).parent().find('.js-product-short-description, .js-product-learn-more-button, .js-product-shades-count').show();
        if ($(this).find('.product-image-alt').length) {
          $(this).find('.js-product-image').hide();
          $(this).find('.product-image-alt').show();
        }
      }).mouseout(function () {
        $(this).parent().find('.js-product-name, .js-product-badge').show();
        $(this).parent().find('.js-product-short-description, .js-product-learn-more-button, .js-product-shades-count').hide();
        if ($(this).find('.product-image-alt').length) {
          $(this).find('.js-product-image').show();
          $(this).find('.product-image-alt').hide();
        }
      });

      // Carousel - move right or inverted right function
      function moveRightOrLeftInverted(p, sliderWidth) {
        var positionLeft;
        var trackWidth;

        if ($(p).parent().find('.js-slider-track').length) {
          positionLeft = parseFloat($(p).parent().find('.js-slider-track').css('left'));
          if ($(p).parent().hasClass('js-product-carousel')) {
            trackWidth = ($(p).parent().find('.js-slider-track').width() - 3 * (sliderWidth));
          } else {
            trackWidth = $(p).parent().find('.js-slider-track').width();
          }
          $(p).parent().find('.js-slider-arrow-left:first').show();
          if (animate === false) {
            $(p).parent().find('.js-slider-track:first').stop().animate({ left: positionLeft -= sliderWidth }, {
              duration: 500,
              start: function () {
                animate = true;
              },
              complete: function () {
                animate = false;
              }
            });
          }
          if (trackWidth - sliderWidth + positionLeft <= 0) {
            $(p).parent().find('.js-slider-arrow-right:first').hide();
          }
        } else if ($(p).parent().find('.js-slider-track-inverted').length) {
          positionLeft = parseFloat($(p).parent().find('.js-slider-track-inverted').css('left'));
          if ($(p).parent().hasClass('js-product-carousel')) {
            trackWidth = ($(p).parent().find('.js-slider-track').width() - 3 * (sliderWidth));
          } else {
            trackWidth = $(p).parent().find('.js-slider-track').width();
          }
          $(p).parent().find('.js-slider-arrow-left-inverted:first').show();
          if (animate === false) {
            $(p).parent().find('.js-slider-track-inverted:first').stop().animate({ left: positionLeft -= sliderWidth }, {
              duration: 500,
              start: function () {
                animate = true;
              },
              complete: function () {
                animate = false;
              }
            });
          }
          if (positionLeft <= 0) {
            $(p).parent().find('.js-slider-arrow-right-inverted:first').hide();
          }
        }
      };

      // Carousel - move left or inverted left function
      function moveLeftOrRightInverted(p, sliderWidth) {
        var positionLeft;
        var trackWidth;

        if ($(p).parent().find('.js-slider-track').length) {
          positionLeft = parseFloat($(p).parent().find('.js-slider-track').css('left'));
          $(p).parent().find('.js-slider-arrow-right:first').show();
          if (animate === false) {
            $(p).parent().find('.js-slider-track:first').stop().animate({ left: positionLeft += sliderWidth }, {
              duration: 500,
              start: function () {
                animate = true;
              },
              complete: function () {
                animate = false;
              }
            });
          }
          if (positionLeft >= 0) {
            $(p).parent().find('.js-slider-arrow-left:first').hide();
          }
        } else if ($(p).parent().find('.js-slider-track-inverted').length) {
          positionLeft = parseFloat($(p).parent().find('.js-slider-track-inverted').css('left'));
          if ($(p).parent().hasClass('js-product-carousel')) {
            trackWidth = ($(p).parent().find('.js-slider-track-inverted').width() - 3 * (sliderWidth));
          } else {
            trackWidth = $(p).parent().find('.js-slider-track-inverted').width();
          }
          $(p).parent().find('.js-slider-arrow-right-inverted:first').show();
          if (animate === false) {
            $(p).parent().find('.js-slider-track-inverted:first').stop().animate({ left: positionLeft += sliderWidth }, {
              duration: 500,
              start: function () {
                animate = true;
              },
              complete: function () {
                animate = false;
              }
            });
          }
          if (trackWidth - sliderWidth - positionLeft <= 0) {
            $(p).parent().find('.js-slider-arrow-left-inverted:first').hide();
          }
        }
      };

      // Carousel - events on arrows
      $('.js-slider-arrow-left, .js-slider-arrow-right,.js-slider-arrow-left-inverted, .js-slider-arrow-right-inverted').on('click', function () {
        var sliderWidth;

        if ($(this).parent().hasClass('js-product-carousel')) {
          sliderWidth = $(this).parent().find('.js-product-wrapper').outerWidth();
          if ($(this).hasClass('js-slider-arrow-left')) {
            moveLeftOrRightInverted(this, sliderWidth);
          } else if ($(this).hasClass('js-slider-arrow-right')) {
            moveRightOrLeftInverted(this, sliderWidth);
          } else if ($(this).hasClass('js-slider-arrow-left-inverted')) {
            moveLeftOrRightInverted(this, sliderWidth);
          } else if ($(this).hasClass('js-slider-arrow-right-inverted')) {
            moveRightOrLeftInverted(this, sliderWidth);
          }
        } else {
          sliderWidth = $(this).parent().find('.js-slider-list').width();
          if ($(this).hasClass('js-slider-arrow-left')) {
            moveLeftOrRightInverted(this, sliderWidth);
          } else if ($(this).hasClass('js-slider-arrow-right')) {
            moveRightOrLeftInverted(this, sliderWidth);
          } else if ($(this).hasClass('js-slider-arrow-left-inverted')) {
            moveLeftOrRightInverted(this, sliderWidth);
          } else if ($(this).hasClass('js-slider-arrow-right-inverted')) {
            moveRightOrLeftInverted(this, sliderWidth);
          }
        }
      });

      // Carousel slider - function to calculate slide width attribute.
      function calculateSlideWidth() {
        var productSlideWidth = ($('.js-product-carousel').width()) / 4;

        $('.js-product-carousel').find('.js-product-wrapper').outerWidth(productSlideWidth);

        var shadeSlideWidth = ($('.js-product-shade-slider').width()) / 5;

        $('.js-slider-slide').outerWidth(shadeSlideWidth);
      };
      calculateSlideWidth();

      // Tout - setting tout in entered position
      $('.mpp-product-coresite').each(function () {
        var toutPosition = 0;
        var productsLength = 0;

        productsLength = $(this).find('.js-product-wrapper').length;
        toutPosition = parseInt($(this).find('.js-tout-wrapper').attr('position'), 10);
        $(this).find('.js-tout-wrapper').css('display', 'inline-block');

        if (toutPosition <= productsLength) {
          $(this).find('.js-product-wrapper:nth-child(' + toutPosition + ')').before($(this).find('.js-tout-wrapper'));
        } else {
          $(this).find('.js-product-wrapper:nth-child(' + productsLength + ')').after($(this).find('.js-tout-wrapper'));
        }
      });

      // Shade slider - setup first active shade for products
      $('.js-slider-track .js-slider-slide:first-child').addClass('active');
      $('.js-slider-track-inverted .js-slider-slide:first-child').addClass('active');

      // Shade slider - change shade on slider
      $('.js-slider-slide').on('click', function () {
        $(this).parent().find('.js-slider-slide.active').removeClass('active');
        $(this).addClass('active');
        $(this).closest('.js-product-wrapper').find('.product-image').attr('src', '/media/export/cms/products/600x750/cl_sku_' + $(this).attr('data-sku-id') + '_600x750_0.png');
      });

      // Shade slider - remove right arrow
      $('.js-slider-list').each(function () {
        if ($(this).width() >= $(this).find('.js-slider-track').width()) {
          $(this).parent().find('.js-slider-arrow-right').hide().removeClass('js-slider-arrow-right');
        }
      });

      // Shade slider - count number of shades text
      $('.js-product-shades-count').each(function () {
        var count = $(this).closest('.js-product-wrapper').find('.js-slider-slide').length;

        $(this).prepend(count + ' ');
      });

      // Remove other popup elements from the code
      $('.js-product-learn-more-wrapper').not(':first').remove();

      // Change parameters on Winddow Resize
      window.onresize = function () {
        $('.js-slider-track').css('left', 0);
        $('.js-slider-arrow-left').hide();
        $('.js-slider-arrow-right').show();
        $('.js-slider-track-inverted').css('left', 0);
        $('.js-slider-arrow-left-inverted').show();
        $('.js-slider-arrow-right-inverted').hide();
        calculateSlideWidth();
      };
    },
    attach: function (context) {
      var self = this;

      self.initProductGridCoresiteV1(context);
    }
  };
})(jQuery);
